import React from 'react';
import { graphql } from 'gatsby';

import Wrapper from '../components/Global/Wrapper';
import Post from '../components/Post';

const SinglePost = ({ data }) => {
  const { sanityPost } = data;
  return (
    <Wrapper title={sanityPost.title} seoSettings={sanityPost.seoSettings}>
      <Post {...sanityPost} />
    </Wrapper>
  );
};

export const PostQuery = graphql`
  query postQuery($id: String!) {
    sanityPost(id: { eq: $id }) {
      _rawBody(resolveReferences: { maxDepth: 10 })
      title
      publishedAt
      mainImage {
        asset {
          fluid(maxWidth: 700) {
            ...GatsbySanityImageFluid
          }
        }
      }
      seoSettings {
        metaDescription
        pageTitle
      }
    }
  }
`;

export default SinglePost;
