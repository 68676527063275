import React from 'react';
import styled from 'styled-components';
import Image from 'gatsby-image';
import { format, parseISO, isThisYear } from 'date-fns';

import PageTitle from './PageTitle';
import { PageMain } from './layout';
import Content from './Content';
import { contentWidth } from './theme';

const Post = ({ _rawBody, title, mainImage, publishedAt }) => {
  const publishDate = parseISO(publishedAt);
  return (
    <PostWrapper>
      <PageTitle className="post-title">{title}</PageTitle>
      <span className="post-date">
        {isThisYear(publishDate)
          ? format(publishDate, 'MMMM d')
          : format(publishDate, 'MMMM d, yyyy')}
      </span>
      {mainImage && (
        <ImageWrapper>
          <Image className="post-main-image" fluid={mainImage.asset.fluid} />
        </ImageWrapper>
      )}
      {_rawBody && (
        <PageMain>
          <Content>{_rawBody}</Content>
        </PageMain>
      )}
    </PostWrapper>
  );
};

const ImageWrapper = styled.div`
  ${contentWidth}
  padding-left: 1rem;
  padding-right: 1rem;
`;
const PostWrapper = styled.div`
  .post-date {
    margin-bottom: 3rem;
    display: block;
    font-family: ${({ theme }) => theme.font.boldFamily};
    color: ${({ theme }) => theme.darkOrange};
    ${contentWidth}
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: center;
  }
`;

export default Post;